import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled, { keyframes } from "styled-components"
import ImageLoader from "react-images-upload"

import { hex2rgba } from "../../styles/tools"
import {
  InputCols,
  InputRow,
  SelectWrapper,
  SubmitButton,
} from "../../styles/FormStyles"
import { sendContactForm, sendContactFormImage } from "../../api/contact"

const ImageForm = styled.form`
  padding: 1rem;

  background-color: ${props => props.theme.color.whiteBg};
  border-radius: 1rem;
  box-shadow: 0 2px 8px 4px ${props =>
    hex2rgba(props.theme.color.primary, 0.1)};

  .fileContainer {
    margin: 0;
    padding: 0rem;

    border-radius: 1rem;
    background-color: transparent;
    border: none;
    box-shadow: none;

    > * {
      order: 1;
    }
  }

  .chooseFileButton {
    margin: 0 0 0.5rem;
    padding: 0.5rem 1.5rem;

    background-color: ${props => props.theme.color.primary};

    font-size: 0.9rem;
    color: #fff;

    border-radius: 5rem;

    &:hover {
      background-color: ${props => props.theme.color.primary};
    }

    &--has-image {
      background-color: transparent;
      color: ${props => props.theme.color.primary};

      box-shadow: 0 0 0 2px ${props => props.theme.color.primary} inset;

      &:hover {
        background-color: transparent;
        color: ${props => props.theme.color.primary};

        box-shadow: 0 0 0 3px ${props => props.theme.color.primary} inset;
      }
    }
  }

  .uploadPicturesWrapper {
    order: 0;
  }

  .uploadPictureContainer {
    margin: 1rem;
    width: auto;

    border-radius: 6px;
    border: none;
    box-shadow: 0 2px 8px 4px
      ${props => hex2rgba(props.theme.color.primary, 0.1)};

    img {
      display: block;
      margin: 0 auto;
      width: auto;
      max-height: 300px;

      border-radius: 4px;

      &.uploadPicture {
        width: auto;
      }
    }
  }

  .deleteImage {
    right: 0;
    top: 0;
    transform: translate(25%, -25%);

    display: flex;
    width: 1.5rem;
    height: 1.5rem;

    color: transparent;

    border-radius: 5rem;
    background-color: firebrick;

    &::after {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      display: flex;
      align-items: center;
      justify-content: center;
      width: 1rem;
      height: 1rem;

      text-align: center;
      line-height: 0.8rem;
      font-size: 1rem;
      color: #fff;
      content: \"\\00d7";
    }
  }
`

const FormSubmitButton = styled(SubmitButton)`
  font-size: 1.2em;
`

const UploadError = styled.div`
  padding: 1rem;

  font-size: 0.8em;
  line-height: 1.2em;
  color: firebrick;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const spinnerAnimation = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`
const Spinner = styled.div`
  position: relative;

  width: 0;
  height: 2rem;

  overflow: hidden;
  transition: all 0.4s ease-in-out;

  &[data-active="true"] {
    width: 3rem;
  }

  &::after {
    position: absolute;
    right: 0.1rem;
    top: 0.1rem;
    transform: rotate(0);
    transform-origin: center;

    display: block;
    width: 1.8rem;
    height: 1.8rem;

    border: 4px solid transparent;
    border-left-color: ${props => props.theme.color.primary};
    border-radius: 8rem;

    animation: ${spinnerAnimation} 1s linear;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;

    content: "";
  }
`

const DairyCodeImage = styled.div`
  margin: 0 0 1.5rem;
`

const ReasonWrapper = styled(SelectWrapper)`
  margin-bottom: 2rem;
`

export default function Form(props) {
  const images = useStaticQuery(graphql`
    query contactPageImages {
      dairyCode: file(relativePath: { eq: "dairy-code.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const reasons = [
    "I have something nice to say!",
    "I have a question or comment.",
    "I have a concern with product.",
    "I have a concern with a store.",
  ]
  const [selectedReason, setSelectedReason] = useState(0)

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")

  const [dairyCode, setDairyCode] = useState("")
  const [bestByDate, setBestByDate] = useState("")

  const [storeName, setStoreName] = useState("")
  const [storeAddress, setStoreAddress] = useState("")
  const [storeCity, setStoreCity] = useState("")
  const [storeState, setStoreState] = useState("")

  const [isLoading, setIsLoading] = useState(false)
  const [submitFormSuccess, setSubmitFormSuccess] = useState(false)
  const [submitImageSuccess, setSubmitImageSuccess] = useState(false)
  const [submitImageError, setSubmitImageError] = useState(false)

  const [caseId, setCaseId] = useState(undefined)
  const [imageUpload, setImageUpload] = useState([])

  const submitForm = async e => {
    e.preventDefault()

    if (!isLoading) {
      setIsLoading(true)

      const postObject = {
        Reason__c: reasons[selectedReason],
        Contact_Name__c: name,
        Email_Address__c: email,
        Phone__c: phone,
        Description: message,
      }

      if (selectedReason === 2) {
        postObject["Cup_Code__c"] = dairyCode
        postObject["Code_Date__c"] = bestByDate
      }

      if (selectedReason === 2 || selectedReason === 3) {
        postObject["Store_Name__c"] = storeName
        postObject["Store_Location__c"] = storeAddress
        postObject["Store_City__c"] = storeCity
        postObject["Store_State__c"] = storeState
      }

      const formData = getFormData(postObject)
      const sendResults = await sendContactForm(formData)

      if (sendResults.success) {
        setCaseId(sendResults.id)
        setSubmitFormSuccess(true)
      }

      setIsLoading(false)
    }
  }

  const getFormData = object => {
    const formData = new FormData()
    Object.keys(object).forEach(key => formData.append(key, object[key]))
    return formData
  }

  const getBase64 = (file, cb) => {
    const reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onload = cb

    reader.onerror = function(error) {
      console.log("reader error", error)
    }
  }

  const updateImage = image => {
    setSubmitImageError(false)
    setImageUpload(image)
  }

  const submitImage = async e => {
    e.preventDefault()

    if (!isLoading && caseId && imageUpload) {
      setIsLoading(true)
      setSubmitImageError(false)

      getBase64(imageUpload[0], async e => {
        const imageData = e.target.result

        const postObject = {
          body: imageData,
          id: caseId,
          filename: "download.png",
          type: "image",
        }

        const formData = getFormData(postObject)
        const sendResults = await sendContactFormImage(formData)

        setIsLoading(false)
        setSubmitImageSuccess(sendResults.success)

        if (!sendResults.success) {
          setSubmitImageError(true)
        }
      })
    }
  }

  return (
    <>
      {!submitFormSuccess && (
        <div>
          <p>We want to hear from you! Give us a call or send a message.</p>

          {/* eslint-disable */}
          <InputRow>
            <label>
              <div>What would you like to reach out to us about?</div>
            </label>
            <ReasonWrapper>
              <select
                value={selectedReason}
                onChange={e => {
                  setSelectedReason(parseInt(e.target.value))
                }}
              >
                {reasons.map((reason, reasonIndex) => (
                  <option value={reasonIndex} key={reasonIndex}>
                    {reason}
                  </option>
                ))}
              </select>
            </ReasonWrapper>
          </InputRow>
          {/* eslint-enable */}

          <form onSubmit={submitForm}>
            <InputRow>
              <label data-required="true">
                <div>Name (first, last)</div>
                <input
                  value={name}
                  onChange={e => setName(e.target.value)}
                  required={true}
                  maxLength="32"
                  size="20"
                  type="text"
                />
              </label>
            </InputRow>

            <InputCols>
              <InputRow>
                <label data-required="true">
                  <div>Email</div>
                  <input
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required={true}
                    maxLength="50"
                    size="20"
                    type="email"
                  />
                </label>
              </InputRow>

              <InputRow>
                <label>
                  <div>Phone</div>
                  <input
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    maxLength="40"
                    size="20"
                    type="tel"
                  />
                </label>
              </InputRow>
            </InputCols>

            <InputRow>
              <label data-required="true">
                <div>Message</div>
                <textarea
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                  required={true}
                  rows="8"
                />
              </label>
            </InputRow>

            {/* Issue with a Product */}
            {selectedReason === 2 && (
              <div>
                <div>
                  <InputCols>
                    <InputRow>
                      <label>
                        <div>Dairy Code (example below)</div>
                        <input
                          value={dairyCode}
                          onChange={e => setDairyCode(e.target.value)}
                          maxLength="15"
                          size="20"
                          type="text"
                        />
                      </label>
                    </InputRow>

                    <InputRow>
                      <label>
                        <div>Best By Date</div>
                        <input
                          value={bestByDate}
                          onChange={e => setBestByDate(e.target.value)}
                          maxLength="25"
                          size="20"
                          type="text"
                        />
                      </label>
                    </InputRow>
                  </InputCols>

                  <DairyCodeImage>
                    <Img fluid={images.dairyCode.childImageSharp.fluid} />
                  </DairyCodeImage>
                </div>
              </div>
            )}

            {(selectedReason === 2 || selectedReason === 3) && (
              <div>
                <InputRow>
                  <label>
                    <div>Store Name</div>
                    <input
                      value={storeName}
                      onChange={e => setStoreName(e.target.value)}
                      maxLength="48"
                      size="20"
                      type="text"
                    />
                  </label>
                </InputRow>

                <InputRow>
                  <label>
                    <div>Store Address</div>
                    <input
                      value={storeAddress}
                      onChange={e => setStoreAddress(e.target.value)}
                      maxLength="48"
                      size="20"
                      type="text"
                    />
                  </label>
                </InputRow>

                <InputCols>
                  <InputRow>
                    <label>
                      <div>Store City</div>
                      <input
                        value={storeCity}
                        onChange={e => setStoreCity(e.target.value)}
                        maxLength="45"
                        size="20"
                        type="text"
                      />
                    </label>
                  </InputRow>

                  <InputRow>
                    <label>
                      <div>Store State</div>
                      {/* eslint-disable */}
                      <SelectWrapper>
                        <select
                          value={storeState}
                          onChange={e => setStoreState(e.target.value)}
                        >
                          <option value=""></option>
                          <option value="AL">Alabama</option>
                          <option value="AK">Alaska</option>
                          <option value="AZ">Arizona</option>
                          <option value="AR">Arkansas</option>
                          <option value="CA">California</option>
                          <option value="CO">Colorado</option>
                          <option value="CT">Connecticut</option>
                          <option value="DE">Delaware</option>
                          <option value="DC">District Of Columbia</option>
                          <option value="FL">Florida</option>
                          <option value="GA">Georgia</option>
                          <option value="HI">Hawaii</option>
                          <option value="ID">Idaho</option>
                          <option value="IL">Illinois</option>
                          <option value="IN">Indiana</option>
                          <option value="IA">Iowa</option>
                          <option value="KS">Kansas</option>
                          <option value="KY">Kentucky</option>
                          <option value="LA">Louisiana</option>
                          <option value="ME">Maine</option>
                          <option value="MD">Maryland</option>
                          <option value="MA">Massachusetts</option>
                          <option value="MI">Michigan</option>
                          <option value="MN">Minnesota</option>
                          <option value="MS">Mississippi</option>
                          <option value="MO">Missouri</option>
                          <option value="MT">Montana</option>
                          <option value="NE">Nebraska</option>
                          <option value="NV">Nevada</option>
                          <option value="NH">New Hampshire</option>
                          <option value="NJ">New Jersey</option>
                          <option value="NM">New Mexico</option>
                          <option value="NY">New York</option>
                          <option value="NC">North Carolina</option>
                          <option value="ND">North Dakota</option>
                          <option value="OH">Ohio</option>
                          <option value="OK">Oklahoma</option>
                          <option value="OR">Oregon</option>
                          <option value="PA">Pennsylvania</option>
                          <option value="RI">Rhode Island</option>
                          <option value="SC">South Carolina</option>
                          <option value="SD">South Dakota</option>
                          <option value="TN">Tennessee</option>
                          <option value="TX">Texas</option>
                          <option value="UT">Utah</option>
                          <option value="VT">Vermont</option>
                          <option value="VA">Virginia</option>
                          <option value="WA">Washington</option>
                          <option value="WV">West Virginia</option>
                          <option value="WI">Wisconsin</option>
                          <option value="WY">Wyoming</option>
                        </select>
                      </SelectWrapper>
                      {/* eslint-enable */}
                    </label>
                  </InputRow>
                </InputCols>
              </div>
            )}

            <FormSubmitButton type="submit" disabled={isLoading}>
              Submit
            </FormSubmitButton>
          </form>
        </div>
      )}

      {submitFormSuccess && !submitImageSuccess && (
        <div>
          <h2>We received your form submission!</h2>
          <p>
            Thank you so much for reaching out to us! Do you have a photo that
            would help you tell your story? Simply upload that here! A f'realie
            will be reaching out to you shortly, have an awesome day!
          </p>

          <ImageForm onSubmit={submitImage}>
            <ImageLoader
              withIcon={false}
              onChange={updateImage}
              imgExtension={[".jpg", ".png", ".gif", ".jpeg"]}
              label={`Max file size: 4mb, accepted: jpg, gif, png`}
              maxFileSize={4000000}
              withPreview={true}
              singleImage={true}
              buttonText={
                imageUpload.length > 0 ? "Change Image" : "Choose Image"
              }
              buttonClassName={
                imageUpload.length > 0 ? "chooseFileButton--has-image" : ""
              }
            />

            <Actions>
              <SubmitButton
                type="submit"
                disabled={isLoading || imageUpload.length <= 0}
                data-highlight={imageUpload.length > 0 ? "true" : "false"}
              >
                Send Image
              </SubmitButton>
              <Spinner data-active={isLoading} />
            </Actions>

            {submitImageError && (
              <UploadError>
                There was an error sending your image, you might refresh the
                page and try again.
              </UploadError>
            )}
          </ImageForm>
        </div>
      )}

      {submitImageSuccess && (
        <div>
          Success! Your image has been uploaded and attached to your message.
          We'll get back to you shortly!
        </div>
      )}
    </>
  )
}
