import axios from "axios"

export const sendContactForm = async formData => {
  // "https://frealapi.wpengine.com/wp-json/finder/v1/locations"
  let response = await axios({
    method: "post",
    url: "https://frealapi.wpengine.com/wp-json/contact/v1/submit-contact-form",
    headers: { "Content-Type": "multipart/form-data" },
    data: formData,
  })

  //   console.log({ response })

  let returnData = {}

  const responseData =
    typeof response.data === "string"
      ? JSON.parse(response.data)
      : response.data
  //   console.log({ responseData })
  //   return returnData

  if (responseData && responseData.id) {
    returnData["id"] = responseData.id
    returnData["success"] = responseData.statusCode === 200
    return returnData
  } else {
    return false
  }
}

export const sendContactFormImage = async formData => {
  let response = await axios({
    method: "post",
    url:
      "https://frealapi.wpengine.com/wp-json/contact/v1/submit-contact-form-image",
    headers: { "Content-Type": "multipart/form-data" },
    data: formData,
  })

  //   console.log({ response })

  let returnData = {}

  const responseData =
    typeof response.data === "string"
      ? JSON.parse(response.data)
      : response.data

  if (responseData) {
    // console.log(responseData)
    returnData["id"] = responseData.id
    returnData["success"] = responseData.statusCode === 200
    return returnData
  } else {
    return false
  }
}
