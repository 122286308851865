import styled from "styled-components"

import { hex2rgba } from "./tools"

export const InputCols = styled.div`
  @media (min-width: 769px) {
    display: flex;
    > div {
      flex-grow: 1;

      &:not(:last-child) {
        margin-right: 1.5rem;
      }
    }
  }
`

export const InputRow = styled.div`
  margin: 0 0 1.75rem;

  text-align: left;

  /* border: 1px solid red; */

  label {
    display: block;

    /* border: 1px solid blue; */

    div {
      margin: 0 0 0.375rem;
      /* border: 1px solid blue; */
    }

    &[data-required="true"] div {
      &::after {
        display: inline-block;
        margin-left: 0.25rem;

        color: firebrick;
        content: "*";
      }
    }
  }

  textarea,
  input[type="text"],
  input[type="tel"],
  input[type="email"] {
    display: block;
    padding: 0.75rem 1.5rem;
    width: 100%;
    max-width: 100%;

    border: none;
    border-radius: 2rem;
    transition: all 0.2s ease-in-out;
    outline: none;

    background-color: ${props => props.theme.color.whiteBg};
    color: ${props => props.theme.color.primary};

    box-shadow: 0 2px 8px 4px
      ${props => hex2rgba(props.theme.color.primary, 0.2)};

    font-weight: bold;

    &:focus,
    &:focus-within {
      background-color: #fff;
      box-shadow: 0 2px 8px 4px
          ${props => hex2rgba(props.theme.color.primary, 0.2)},
        0 0 0 3px ${props => hex2rgba(props.theme.color.highlight, 0.3)} inset;
    }
  }
`

export const SelectWrapper = styled.div`
  position: relative;

  text-align: left;

  /* border: 2px solid red; */
  border-radius: 5rem;

  transition: all 0.2s ease-in-out;
  outline: none;

  background-color: ${props => props.theme.color.whiteBg};
  color: ${props => props.theme.color.primary};

  box-shadow: 0 2px 8px 4px ${props => hex2rgba(props.theme.color.primary, 0.2)};

  font-weight: bold;

  select {
    display: block;
    padding: 0.75rem 1.5rem;
    width: 100%;

    border-radius: 2rem;
    cursor: pointer;

    background: transparent;
    border: none;

    color: ${props => props.theme.color.primary};
    -webkit-appearance: none;

    &:focus,
    &:focus-within {
      background-color: #fff;
    }
  }

  &::after {
    position: absolute;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);

    border: 0.4rem solid ${props => props.theme.color.primary};
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-width: 0;

    content: "";
  }
`

export const SubmitButton = styled.button`
  margin: 0.5rem 0;
  padding: 0.5rem 1.5rem;

  background-color: ${props => props.theme.color.primary};

  font-size: 0.9rem;
  color: #fff;

  border: none;
  border-radius: 5rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${props => props.theme.color.primary};
  }

  &[disabled],
  &:disabled {
    opacity: 0.25;
  }
`
